import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ title, description, image, pathname, article, is404 }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            defaultImage
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { defaultTitle, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || defaultImage,
        url: `${siteUrl}${pathname || '/'}`,
        article,
      };

      return (
        <>
          <Helmet title={seo.title} defer={false}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <link rel="canonical" href={seo.url} />
            {!is404 && <meta name="ROBOTS" content="INDEX, FOLLOW" />}
            {is404 && <meta name="robots" content="noindex" />}
            {/* FB/OPEN GRAPH TAGS */}
            <meta property="og:url" content={seo.url} />
            {article ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={seo.title} />
            <meta property="og:site_name" content="Mindset Family Therapy" />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            {/* TWIITER CARD */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
            {/* CALL RAIL */}
            <script type="text/javascript" src="//cdn.callrail.com/companies/737666631/9832a751876f30111b8b/12/swap.js"></script>
          </Helmet>
        </>
      );
    }}
  />
);

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  is404: false,
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  is404: PropTypes.bool,
};

export default SEO;
